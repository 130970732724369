


















import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { DeleteDaoViewModel } from '../viewmodels/delete-dao-viewmodel'

@Observer
@Component
export default class DeletePost extends Vue {
  @Provide() vm = new DeleteDaoViewModel()
}
